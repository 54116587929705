import { Injectable } from "@angular/core";
import {
  BaseApiResponse,
  ErrorResponse,
  ListData,
} from "../models/bases/base-api-response";

@Injectable({
  providedIn: "root",
})
export class Utils {
  private mongoIDReg = /^[a-fA-F0-9]{24}$/;
  constructor() {}

  isMongoDbID(value: string): boolean {
    return this.mongoIDReg.test(value);
  }

  isErrorResponse(response: BaseApiResponse<any>): response is ErrorResponse {
    return response.errors !== undefined;
  }

  isListData<T>(response: any): response is ListData<T> {
    return response.list !== undefined;
  }

  isData<T>(response: any): response is T {
    return response && !response.errors && !response.list;
  }
}
